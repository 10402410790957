<template>
  <button type="button" @click="logInWithFacebook">
    <img src="@/assets/image/facebook.webp" width="40" height="40" alt="Inicio de sesión Facebook">
  </button>
</template>
<script>
export default {
  name:"facebookLogin",
  mounted(){
    if (this.query.code) {
      localStorage.setItem("loginFacebook", JSON.stringify({
        clientId: this.appId,
        code: this.query.code,
        redirectUri: this.UrlPage,
        url: '/api/auth/facebook'
      }))
      //this.$emit('facebook', )
      window.close()
    }
  },
  methods: {
    logInWithFacebook() {
      var mod = this
      const qs = Object.keys(mod.params)
                    .map(key => `${key}=${mod.params[key]}`)
                    .join('&')
      var url = `https://www.facebook.com/v2.3/dialog/oauth?${qs}`
      var facebook = window.open(url, 'facebook', "width=580,height=400,left=393,top=131.2")
      facebook.onbeforeunload = function(){
        mod.setToken()
      }
    },
    setToken(){
      const items = JSON.parse(localStorage.getItem("loginFacebook"))
      localStorage.removeItem("loginFacebook")
      this.$emit('facebook', items)
    }
  },
  computed: {
    query(){
      return this.$route.query || {}
    },
    params(){
      return {
        "response_type": "code",
        "client_id": this.appId,
        "redirect_uri": this.UrlPage,
        "display": "popup",
        "scope": "email"
      }
    },
    appId(){
      return process.env.NODE_ENV === 'production' ? '902160033577121' : '549430247008190'
    },
    UrlPage(){
      return window.url+"/ingresar"
    }
  }
};
</script>