<template>
  <button type="button" :disabled="!Vue3GoogleOauth.isInit" @click="handleClickGetAuthCode">
    <img src="@/assets/image/google.webp" width="40" height="40" alt="Google">
  </button>
</template>
<script>
import { inject, toRefs } from "vue"

export default {
  name: "Google",
  props: {
    msg: String,
  },
  data(){
    return {
      user: '',
    }
  },

  methods: {
    async handleClickGetAuthCode(){
      try {
        const authCode = await this.$gAuth.getAuthCode()
        this.$emit('google', {
          clientId: window.codeGoogle,
          code: authCode,
          redirectUri: window.location.origin,
          url: '/api/auth/google'
        })
      } catch(error) {
        //on fail do something
        return error
      }
    },
  },
  setup(props) {
    const { isSignIn } = toRefs(props)
    const Vue3GoogleOauth = inject("Vue3GoogleOauth")

    const handleClickLogin = () => {}
    return {
      Vue3GoogleOauth,
      handleClickLogin,
      isSignIn,
    }
  },
}
</script>